<template>
  <v-row justify="center">
    <v-dialog
      :value="value"
      @input="e => $emit('input', e)"
      max-width="900px"
      scrollable="scrollable"
    >
      <v-card>
        <v-card-title
          ><span
            class="headline"
            v-if="!searchExpanded || $vuetify.breakpoint.mdAndUp"
            >{{ title }}</span
          >
          <v-spacer
            v-if="!searchExpanded || $vuetify.breakpoint.mdAndUp"
          ></v-spacer>
          <app-search :expanded.sync="searchExpanded" :type="type"></app-search>
        </v-card-title>
        <v-divider v-if="$vuetify.breakpoint.xsOnly"></v-divider>
        <v-card-text class="pa-0" :style="cardTextStyle">
          <v-container fluid="fluid">
            <collection-data-table
              :filters="filters"
              :items-per-page="5"
              :show-select="showSelect"
              :single-select="singleSelect"
              :type="type"
              v-if="collection"
              v-model="selected"
            ></collection-data-table>
          </v-container>
        </v-card-text>
        <v-divider v-if="$vuetify.breakpoint.xsOnly"></v-divider>
        <v-card-actions>
          <v-btn @click="() => $emit('input', false)">Cancelar</v-btn>
          <v-btn @click="add">Novo cadastro</v-btn>
          <v-spacer></v-spacer>
          <v-btn :disabled="!selected[0]" @click="onSelected" color="accent"
            >Selecionar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <dialog-edit :type="type" ref="dialogEdit" v-if="dialogEdit"></dialog-edit>
  </v-row>
</template>

<script>
import CollectionDataTable from "./CollectionDataTable";
import AppSearch from "@/components/AppSearch.vue";
import DialogEdit from "@/components/DialogEdit";

export default {
  name: "collection-dialog",
  props: {
    filters: Object,
    showSelect: { type: Boolean, default: true },
    singleSelect: { type: Boolean, default: true },
    title: String,
    type: String,
    value: Boolean,
  },
  components: { AppSearch, CollectionDataTable, DialogEdit },

  data: () => ({
    collection: false,
    searchExpanded: false,
    selected: [],
    dialogEdit: false,
  }),

  computed: {
    cardTextStyle() {
      return this.$vuetify.breakpoint.xsOnly ? "height: 450px;" : "";
    },
  },

  watch: {
    value(v) {
      if (v) {
        this.collection = v;
      } else {
        setTimeout(() => {
          this.collection = v;
          this.selected = [];
        }, 400);
      }
    },
  },

  methods: {
    add() {
      if (!this.dialogEdit) {
        this.dialogEdit = true;
      }
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.dialogEdit && this.$refs.dialogEdit.open();
        }, 250);
      });
    },

    onSelected() {
      this.$emit("input", false);
      this.$emit("data-selected", this.selected);
    },
  },
};
</script>
