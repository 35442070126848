<template>
  <v-dialog
    v-model="dialog"
    persistent="persistent"
    scrollable="scrollable"
    max-width="500px"
  >
    <v-form ref="form" v-model="valid" lazy-validation="lazy-validation">
      <v-card :disabled="loading" :loading="loading">
        <v-card-title
          ><span class="headline">{{ title }}</span></v-card-title
        >
        <v-card-text style="height: 600px">
          <v-row>
            <v-col cols="12">
              <v-text-field
                :rules="rules.name"
                @keypress.enter="$refs.name.blur()"
                label="Nome do animal"
                ref="name"
                v-model="doc.name"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-menu
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y="offset-y"
                max-width="290px"
                min-width="290px"
                readonly="readonly"
                v-model="menuBirth"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :rules="rules.birth"
                    :value="birthFormatted"
                    append-icon="mdi-calendar-star"
                    label="Nascimento"
                    readonly="readonly"
                    ref="birth"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :max="$day().toJSON().substr(0, 10)"
                  @change="menuBirth = false"
                  locale="pt-br"
                  min="1980-01-01"
                  no-title="no-title"
                  v-model="doc.birth"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                :filter="filterText"
                :items="speciesList"
                :rules="rules.species"
                label="Espécie"
                no-data-text="Nenhum resultado"
                ref="species"
                v-model="doc.species"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                :filter="filterBreed"
                :items="breedList"
                :rules="rules.breed"
                item-text="nome"
                label="Raça"
                no-data-text="Selecione uma espécie"
                ref="breed"
                return-object="return-object"
                v-model="doc.breed"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                :filter="filterText"
                :items="sizeList"
                :rules="rules.size"
                label="Porte"
                no-data-text="Nenhum resultado"
                ref="size"
                v-model="doc.size"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-select
                :items="['Macho', 'Fêmea']"
                :rules="rules.gender"
                label="Sexo"
                ref="gender"
                v-model="doc.gender"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="accent" text="text" @click="closeAction"
            >Cancelar</v-btn
          >
          <v-btn color="accent" text="text" @click="saveAction">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { especies, portes } from "@zettabrasil/petshop-especies";
import { db } from "../../plugins/google/firebase";
import $store from "@/store";

const { COLLECTION_ANIMALS } = $store.getters,
  SOURCE = { source: "cache" };

const defaultDoc = {
  birth: null,
  breed: { nome: null },
  color: null,
  comments: null,
  gender: null,
  name: null,
  owner: { name: null },
  pelage: null,
  size: null,
  species: null,
  status: "Ativo",
  weight: null,
};

export default {
  name: "animal-edit",
  props: ["title"],

  data: () => ({
    dialog: false,
    loading: false,
    inserting: true,
    valid: true,
    docRef: null,
    // firestore doc
    doc: Object.assign({}, defaultDoc),

    rules: {
      birth: [(v) => !!v || "Campo obrigarório"],
      breed: [(v) => !!v.nome || "Campo obrigarório"],
      gender: [(v) => !!v || "Campo obrigarório"],
      name: [
        (v) => !!v || "Campo obrigarório",
        (v) => (v && v.length > 1) || "Mínimo 2 caracteres",
      ],
      size: [(v) => !!v || "Campo obrigarório"],
      species: [(v) => !!v || "Campo obrigarório"],
    },

    menuBirth: false,
    breedList: [],
    speciesList: [],
    sizeList: [],
  }),

  watch: {
    "doc.species"(v) {
      this.breedList = especies[v];
    },

    "doc.breed"(v) {
      v.porte && (this.doc.size = v.porte);
    },
  },

  computed: {
    birthFormatted() {
      return this.doc.birth
        ? this.$day(this.doc.birth).format("DD/MM/YYYY")
        : "";
    },
  },

  methods: {
    filterBreed(item, queryText) {
      const text = item.nome.toLowerCase();
      const searchText = queryText.toLowerCase();
      return text.indexOf(searchText) > -1;
    },

    filterText(item, queryText) {
      const text = item.toLowerCase();
      const searchText = queryText.toLowerCase();
      return text.indexOf(searchText) > -1;
    },

    closeAction() {
      this.dialog = false;
      setTimeout(() => {
        this.loading = false;
        this.docRef = null;
        this.inserting = true;
        this.speciesList = [];
        this.sizeList = [];
        this.doc = Object.assign({}, defaultDoc);
      }, 400);
    },

    openAction(id) {
      this.dialog = true;
      if (id) {
        this.inserting = false;
        this.getDataFromApi(id);
      } else {
        this.docRef = db.collection(COLLECTION_ANIMALS).doc();
      }
      this.speciesList = Object.keys(especies);
      this.sizeList = portes;

      setTimeout(() => {
        this.$refs.name.focus();
      }, 450);
    },

    getDataFromApi(id) {
      this.docRef = db.collection(COLLECTION_ANIMALS).doc(id);

      this.docRef
        .get(SOURCE)
        .then((snapshot) => {
          const newDoc = snapshot.data();
          newDoc.breed = { nome: newDoc.breed };
          newDoc.owner = newDoc.owner || { name: "" };
          this.doc = newDoc;
        })
        .catch(() => {
          this.$snackbar.show({ title: "Erro ao buscar dados, sem conexão" });
        });
    },

    getStateData() {
      return {
        ...this.doc,
        breed: this.doc.breed.nome,
        owner: this.doc.owner.id ? this.doc.owner : null,
      };
    },

    saveAction() {
      requestAnimationFrame(() => {
        if (this.$refs.form.validate()) {
          this.saveToFirestore();
        }
      });
    },

    async saveToFirestore() {
      try {
        const doc = this.getStateData();
        doc.updatedAt = new Date().toJSON();

        if (this.inserting) {
          doc.createdAt = doc.updatedAt;
        }

        this.docRef.set(doc, { merge: true });
        this.$snackbar.show({ title: "Dados salvos com sucesso" });
        setTimeout(() => this.closeAction(), 400);
      } catch (e) {
        this.$snackbar.show({ title: "Erro ao salvar dados, sem conexão" });
      }
    },
  },
};
</script>
