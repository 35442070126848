<template>
  <v-dialog
    v-model="dialog"
    persistent="persistent"
    scrollable="scrollable"
    max-width="500px"
  >
    <v-card :disabled="loading" :loading="loading">
      <v-card-title
        ><span class="headline">{{ title }}</span></v-card-title
      >
      <v-card-text style="height: 600px">
        <v-row>
          <v-col cols="12">
            <v-text-field
              :error-messages="error.name"
              @focus="error.name = null"
              @keypress.enter="$refs.code.focus()"
              label="Descrição"
              ref="name"
              v-model="doc.name"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              :error-messages="error.code"
              @focus="error.code = null"
              @keypress.enter="$refs.price.focus()"
              label="Código"
              ref="code"
              v-model="doc.code"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              @keypress.enter="$refs.stock.focus()"
              label="Valor (R$)"
              placeholder="0,00"
              ref="price"
              type="tel"
              v-currency="priceOptions"
              v-model="doc.price"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              @keypress.enter="$refs.stock.blur()"
              label="Estoque"
              placeholder="0,000"
              ref="stock"
              type="tel"
              v-currency="stockOptions"
              v-model="doc.stock"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-textarea
              auto-grow="auto-grow"
              filled="filled"
              label="Observações"
              row-height="20"
              rows="2"
              v-model="doc.comments"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="accent" text="text" @click="closeAction">Cancelar</v-btn>
        <v-btn color="accent" text="text" @click="saveAction">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { db } from "../../plugins/google/firebase";
import { parseCurrency, setValue } from "vue-currency-input";
import firestore from "../../plugins/firestore";

const defaultDoc = {
  code: "",
  comments: "",
  name: "",
  price: "",
  stock: "",
};

export default {
  name: "item-edit",
  props: ["title"],

  data: () => ({
    dialog: false,
    loading: false,
    inserting: true,
    docRef: null,
    // firestore doc
    doc: Object.assign({}, defaultDoc),

    error: {
      code: null,
      name: null,
    },
  }),

  computed: {
    priceOptions() {
      return {
        autoDecimalMode: true,
        currency: null,
        precision: 2,
      };
    },
    stockOptions() {
      return {
        autoDecimalMode: true,
        currency: null,
        precision: 3,
      };
    },
  },

  methods: {
    closeAction() {
      this.dialog = false;
      this.loading = false;
      setTimeout(() => {
        this.docRef = null;
        this.inserting = true;
        this.doc = Object.assign({}, defaultDoc);
      }, 400);
    },

    openAction(id) {
      this.dialog = true;
      if (id) {
        this.inserting = false;
        this.getDataFromApi(id);
      } else {
        this.docRef = db.collection(this.$store.getters.COLLECTION_ITEMS).doc();
      }
      setTimeout(() => {
        this.$refs.name.focus();
      }, 450);
    },

    getStateData() {
      const doc = Object.assign({}, this.doc),
        price = parseCurrency(doc.price, this.priceOptions),
        stock = parseCurrency(doc.stock, this.stockOptions);

      doc.price = price !== null ? price : 0;
      doc.stock = stock !== null ? stock : 0;

      return doc;
    },

    getDataFromApi(id) {
      this.docRef = db.collection(this.$store.getters.COLLECTION_ITEMS).doc(id);

      this.docRef
        .get({ source: "cache" })
        .then((snapshot) => {
          const doc = snapshot.data();
          this.doc = Object.assign(
            {},
            {
              code: doc.code,
              comments: doc.comments,
              name: doc.name,
              price: "",
              stock: "",
            },
          );
          doc.price && setValue(this.$refs.price.$refs.input, doc.price);
          doc.stock && setValue(this.$refs.stock.$refs.input, doc.stock);
        })
        .catch(() => {
          this.$snackbar.show({ title: "Erro ao buscar dados, sem conexão" });
        });
    },

    async saveAction() {
      if (!this.validate()) {
        return;
      }

      try {
        this.loading = true;
        const doc = this.getStateData();
        doc.updatedAt = this.$day().toJSON();

        if (this.inserting) {
          doc.createdAt = doc.updatedAt;
        }

        await firestore.setItem(this.docRef, doc);

        this.$snackbar.show({ title: "Dados salvos com sucesso" });
        setTimeout(() => this.closeAction(), 400);
      } catch (e) {
        this.loading = false;
        if (e.code && e.code === "code_exists") {
          this.codeError = e.msg;
        }
        this.$snackbar.show({
          title: e.msg || "Erro ao salvar dados, sem conexão",
        });
      }
    },

    validate() {
      if (!this.doc.name) {
        this.error.name = "Campo obrigatório";
        return false;
      }

      return true;
    },
  },
};
</script>
