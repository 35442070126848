<template>
  <v-dialog
    v-model="dialog"
    persistent="persistent"
    scrollable="scrollable"
    max-width="500px"
  >
    <v-form ref="form" v-model="valid" lazy-validation="lazy-validation">
      <v-card
        :disabled="loading || loadingAddress"
        :loading="loading || loadingAddress"
      >
        <v-card-title
          ><span class="headline">{{ title }}</span></v-card-title
        >
        <v-card-text style="height: 600px">
          <v-row>
            <v-col cols="12">
              <v-text-field
                :rules="rules.name"
                @keypress.enter="$refs.document.focus()"
                label="Nome completo"
                ref="name"
                v-model="doc.name"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                :label="doc.document.length > 14 ? 'CNPJ' : 'CPF'"
                :rules="rules.document"
                @keypress.enter="$refs.role.focus()"
                ref="document"
                type="tel"
                v-mask="['###.###.###-##', '##.###.###/####-##']"
                v-model="doc.document"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-select
                :items="['Cliente', 'Outros']"
                :rules="rules.role"
                @keypress.enter="$refs.email.focus()"
                label="Função"
                ref="role"
                v-model="doc.role"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-text-field
                :rules="rules.email"
                @keypress.enter="$refs.phone.focus()"
                label="E-mail"
                ref="email"
                type="email"
                v-model="doc.email"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                @keypress.enter="$refs.zipcode.focus()"
                label="Telefone"
                ref="phone"
                type="tel"
                v-mask="['(##) ####-####', '(##) #####-####']"
                v-model="doc.phone"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                :rules="rules.zipcode"
                @blur="getZipcode"
                @keypress.enter="getZipcode"
                label="CEP"
                ref="zipcode"
                type="tel"
                v-mask="'#####-###'"
                v-model="doc.address.zipcode"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                :rules="rules.city"
                @keypress.enter="$refs.state.focus()"
                label="Município"
                ref="city"
                v-model="doc.address.city"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                :filter="customFilter"
                :items="stateList"
                :rules="rules.state"
                @keypress.enter="$refs.state.blur()"
                item-text="name"
                label="Estado"
                no-data-text="Nenhum resultado"
                ref="state"
                return-object="return-object"
                v-model="doc.address.state"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="accent" text="text" @click="closeAction"
            >Cancelar</v-btn
          >
          <v-btn color="accent" text="text" @click="saveAction">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mask } from "vue-the-mask";
import utils from "../../plugins/utils";
import getCep from "../../plugins/getCep";
import { db } from "../../plugins/google/firebase";
import $store from "@/store";

const { COLLECTION_ACCOUNTS } = $store.getters,
  SOURCE = { source: "cache" };

const defaultDoc = {
  address: {
    complement: "",
    coords: { latitude: 0, longitude: 0 },
    city: "",
    district: "",
    number: "",
    state: { initials: "", name: "" },
    street: "",
    zipcode: "",
  },
  document: "",
  email: "",
  genre: "",
  name: "",
  phone: "",
  role: "",
};

export default {
  name: "account-edit",
  directives: { mask },
  props: ["title"],

  data: () => ({
    dialog: false,
    loading: false,
    loadingAddress: false,
    inserting: true,
    valid: true,
    docRef: null,
    // firestore doc
    doc: JSON.parse(JSON.stringify(defaultDoc)),

    stateList: utils.ufs,

    rules: {
      city: [(v) => !!v || "Campo obrigarório"],
      document: [],
      email: [(v) => utils.validateEmail(v) || "E-mail deve ser válido"],
      name: [
        (v) => !!v || "Campo obrigarório",
        (v) => (v && v.length > 2) || "Mínimo 3 caracteres",
      ],
      role: [(v) => !!v || "Campo obrigarório"],
      state: [(v) => !!v || "Campo obrigarório"],
      zipcode: [
        (v) => !!v || "Campo obrigarório",
        (v) => (v && v.length > 8) || "CEP deve ser válido",
      ],
    },
  }),

  methods: {
    closeAction() {
      this.dialog = false;
      setTimeout(() => {
        this.loading = false;
        this.docRef = null;
        this.inserting = true;
        this.rules.document = [];
        this.doc = JSON.parse(JSON.stringify(defaultDoc));
      }, 400);
    },

    openAction(id) {
      this.dialog = true;

      if (id) {
        this.inserting = false;
        this.getDataFromApi(id);
      } else {
        this.docRef = db.collection(COLLECTION_ACCOUNTS).doc();
      }
      this.rules.document = [
        (v) => !!v || "Campo obrigarório",
        (v) =>
          utils.validateDocs(v, this.doc.document.length > 14 ? "J" : "F") ||
          "Documento inválido",
      ];

      setTimeout(() => {
        this.$refs.name.focus();
      }, 450);
    },

    customFilter(item, queryText) {
      const one = item.name.toLowerCase();
      const two = item.initials.toLowerCase();
      const searchText = queryText.toLowerCase();
      return one.indexOf(searchText) > -1 || two.indexOf(searchText) > -1;
    },

    getZipcode() {
      if (!this.dialog || this.loadingAddress) {
        return;
      }
      this.loadingAddress = true;
      getCep(utils.cepMask(this.doc.address.zipcode, true))
        .then((d) => {
          if (d && d.cep) {
            d.logradouro && (this.doc.address.street = d.logradouro);
            d.complemento && (this.doc.address.complement = d.complemento);
            d.bairro && (this.doc.address.district = d.bairro);
            if (d.municipio) {
              this.doc.address.city = d.municipio.nome;
              if (d.municipio.uf) {
                this.doc.address.state = {
                  initials: d.municipio.uf.sigla,
                  name: d.municipio.uf.nome,
                };
              }
            }
          }
        })
        .catch(() => null)
        .finally(() => {
          this.loadingAddress = false;
          setTimeout(() => this.$refs.city.focus(), 250);
        });
    },

    getDataFromApi(docRef) {
      this.docRef = db.collection(COLLECTION_ACCOUNTS).doc(docRef);

      this.docRef
        .get(SOURCE)
        .then((snapshot) => {
          const newDoc = snapshot.data();
          newDoc.document = utils.docMask(newDoc.document);
          this.doc = newDoc;
        })
        .catch(() => {
          this.$snackbar.show({ title: "Erro ao buscar dados, sem conexão" });
        });
    },

    getStateData() {
      return {
        address: this.doc.address,
        document: utils.docMask(this.doc.document, true),
        email: this.doc.email,
        genre: this.doc.genre,
        name: this.doc.name,
        phone: utils.phoneMask(this.doc.phone, true),
        role: this.doc.role,
      };
    },

    saveAction() {
      if (this.$refs.form.validate()) {
        this.saveToFirestore();
      }
    },

    async validateData() {
      const { document, email } = this.doc;
      const docRef = db.collection(COLLECTION_ACCOUNTS),
        docQuery = docRef.where(
          "document",
          "==",
          utils.docMask(document, true),
        ),
        emailQuery = docRef.where("email", "==", email);

      const docSnapshot = await docQuery.get(SOURCE);
      const emailSnapshot = await emailQuery.get(SOURCE);
      let docCount = false,
        emailCount = false;

      docSnapshot.forEach((val) => {
        if (val.id !== this.docRef.id) {
          docCount = true;
        }
      });
      emailSnapshot.forEach((val) => {
        if (val.id !== this.docRef.id) {
          emailCount = true;
        }
      });

      if (docCount) {
        this.$snackbar.show({ title: "Documento informado já existe" });
        return false;
      }
      if (emailCount) {
        this.$snackbar.show({ title: "E-mail informado já existe" });
        return false;
      }
      return true;
    },

    async saveToFirestore() {
      try {
        if (!(await this.validateData())) {
          return;
        }

        const doc = this.getStateData();
        doc.updatedAt = new Date().toJSON();

        if (this.inserting) {
          doc.createdAt = doc.updatedAt;
        }

        this.docRef.set(doc, { merge: true });
        this.$snackbar.show({ title: "Dados salvos com sucesso" });
        setTimeout(() => this.closeAction(), 400);
      } catch (e) {
        this.$snackbar.show({ title: "Erro ao salvar dados, sem conexão" });
      }
    },
  },
};
</script>
