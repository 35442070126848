<template>
  <component
    :is="currentElement"
    :title="titleComputed"
    ref="dialog"
  ></component>
</template>

<script>
import AccountEdit from "./AccountEdit.vue";
import AnimalEdit from "./AnimalEdit.vue";
import ItemEdit from "./ItemEdit.vue";

export default {
  name: "dialog-edit",
  components: { AccountEdit, AnimalEdit, ItemEdit },
  props: ["type"],

  computed: {
    currentElement() {
      switch (this.type) {
        case "accounts":
          return "account-edit";
        case "animals":
          return "animal-edit";
        case "items":
          return "item-edit";
        default:
          return "";
      }
    },

    titleComputed() {
      switch (this.type) {
        case "accounts":
          return "Cadastro cliente";
        case "animals":
          return "Cadastro animal";
        case "items":
          return "Cadastro item";
        default:
          return "";
      }
    },
  },

  methods: {
    open(id) {
      this.$refs.dialog && this.$refs.dialog.openAction(id);
    },
  },
};
</script>
